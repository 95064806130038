import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import "./ContactSupport.scss";
import { setIsContactSupportModal } from "src/app/redux/actions/actions";
import { supportEmail } from "src/app/utils/Constants";

const ContactSupport = () => {
  const isContactSupportModal = useSelector(
    (state) => state?.loginReducer?.isContactSupportModal
  );
  const dispatch = useDispatch();
  const hideModal = () => {
    dispatch(setIsContactSupportModal(false));
  };
  return (
    <Modal className="modal-contact-support" show={isContactSupportModal} onHide={hideModal}>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="modal-body">
        <div className="support-title">Contact Support</div>
        <div>
          Call or email one of our support representatives to help resolve your
          issue.
        </div>
        <div className="support-sec">
          <span>Phone Number</span>
          <span>
            <a href="tel:8009604196">800-960-4196</a>
          </span>
        </div>
        <div className="support-sec">
          <span>Email</span>
          <span>
            <a href={`mailto:${supportEmail}`}>
              {supportEmail}
            </a>
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ContactSupport;
