import { applicationErrorReducer } from './applicationReducer';
import { sidebarReducer } from './sidebarReducer';
import { manageLeadsReducer } from './manageLeadsReducer';
import { orderLeadsReducer } from './orderLeadsReducer';
import { loginReducer } from './reducer';
import { combineReducers } from 'redux';
import { shoppingCartReducer } from './shoppingCartReducer';
import { agentReducer } from './AgentReducer';
import { couponReducer } from './couponReducer';
import { eGiftsReducer } from './eGiftsReducer';
import { RtlCampaignsReducer } from './RealTimeLeadsReducer';
import { userCreditsReducer } from './userCreditsReducer';
import { transactionsReducer } from './transactionHistoryReducer';
import { featuresReducer } from './featuresReducer';
import { faqReducer } from './faqReducer';
import { userProfilesReducer } from './userProfilesReducer';
import { vendorCreditsReducer } from './vendorCreditsReducer';
import { agentWelcomeModalReducer } from './agentWelcomeModalReducer';
import { agentReferralModalReducer } from './agentReferralModalReducer';
import { miscellaneousOrderReducer } from './miscellaneousOrderReducer';
import { paymentMethodsReducer } from './paymentMethodsReducer';
import { bannerReducer } from './bannerReducer';
import usersSearchReducer from './manageAgentsReducer';

export default combineReducers({
  application: combineReducers({
    errors: applicationErrorReducer,
  }),
  loginReducer,
  manageLeadsReducer,
  orderLeadsReducer,
  sidebarReducer,
  shoppingCartReducer,
  agentReducer,
  couponReducer,
  eGiftsReducer,
  RtlCampaignsReducer,
  userCreditsReducer,
  transactionsReducer,
  featuresReducer,
  faqReducer,
  userProfilesReducer,
  vendorCreditsReducer,
  agentWelcomeModalReducer,
  agentReferralModalReducer,
  miscellaneousOrderReducer,
  paymentMethodsReducer,
  bannerReducer,
  usersSearchReducer,
});
